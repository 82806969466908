
import {defineAsyncComponent, defineComponent, ref} from "vue";
import Input from "@/types/Input";
import {useFoldersStore} from "@/stores/folders";
import {storeToRefs} from "pinia";

export default defineComponent({
  name: "FormTemplate",
  components: {
    Input: defineAsyncComponent(() => import('@/components/Input.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    Checkbox: defineAsyncComponent(() => import('@/components/Checkbox.vue')),
    Radio: defineAsyncComponent(() => import('@/components/Radio.vue')),
    Select: defineAsyncComponent(() => import('@/components/Select.vue')),
    TextArea: defineAsyncComponent(() => import('@/components/TextArea.vue')),
    UploadFile: defineAsyncComponent(() => import('@/modales/UploadFile.vue')),
  },
  props: {
    inputs: { type: Array, default: () => [] },
    title: { type: String, default: '' },
    readOnly: { type: Boolean, default: false },
    formId: { type: String, default: '' }
  },
  setup(props) {
    //STORE folders
    const foldersStore = useFoldersStore();
    const { currentFolder } = storeToRefs(foldersStore);

    //DATA
    const errorCount = ref(0);
    const openUploadfile = ref (false);

    const updateCheckbox = (args: any, choices: Array<any>, values: Array<any>) => {
      let item: Input = {};
      choices.forEach((choice: any) => {
        if (choice.name === args.id.split('-')[1]) item = choice;
      });
      let index: number = values.indexOf(item);
      if (args.checked) {
        if (index < 0) {
          values.push(item.id);
        }
      } else {
        values.splice(index, 1);
      }
    };

    const updateError = (isError: boolean) => {
      console.log('error', isError);
      isError ? errorCount.value++ : errorCount.value--;
    };

    const checkCondition = (slaveInput: any) => {
      if (slaveInput.condition === undefined) return true;
      else {
        let masterInput: any = props.inputs.find((input: any) => input.field === slaveInput.condition.field);
        if (masterInput.type === 'checkbox') {
          return masterInput.value.includes(slaveInput.condition.value);
        } else return masterInput.value === slaveInput.condition.value;
      }
    };

    const computedValue = (value: any) => {
      if (typeof value !== 'boolean') {
        return value;
      } else {
        if (value) return 'Oui';
        else return 'Non';
      }
    };

    const uploadFileSuccess = (input: Input, files: any) => {
      openUploadfile.value = false;
      files.forEach((file: any) => input.value.push(file.name))
      console.log('test', files);
    };

    return { updateError, errorCount, updateCheckbox, checkCondition, openUploadfile, uploadFileSuccess, currentFolder, computedValue }
  },
});
